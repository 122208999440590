import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/auth.scss";
import { motion } from "framer-motion";

const updateThemeColor = (color) => {
  // Find the meta tag or create one if it doesn't exist
  let themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
  if (!themeColorMetaTag) {
    themeColorMetaTag = document.createElement("meta");
    themeColorMetaTag.setAttribute("name", "theme-color");
    document.getElementsByTagName("head")[0].appendChild(themeColorMetaTag);
  }

  // Update the content attribute to the new color
  themeColorMetaTag.setAttribute("content", color);
};

const AuthLayout = ({ page, children, heading, subheading }) => {
  useEffect(() => {
    updateThemeColor("#3850D8");
  }, []);

  return (
    <div className="auth-screens">
      <div className="block md:flex flex-row overflow-hidden min-h-screen flex-1">
        <div className="w-full lg:w-3/5 flex flex-col justify-between p-5 sm:p-12 md:p-20">
          <div className="w-full mb-8 relative">
            <div className="">
              <Link to={"/"}>
                <img className="h-16 invert" alt="PolicyPortal Logo" src="/logo.png" />
              </Link>
            </div>
          </div>
          <div className="w-full">
            <h1 className="heading">Lorem ipsum dolor sit consectetur adipiscing elit</h1>
            <h6 className="subheading">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <br/><br/>
              <ul className="list-disc ml-5">
                  <li>consectetur adipiscing elit</li>
                  <li>sed do eiusmod tempor incididunt</li>
                  <li>consectetur adipiscing elit</li>
                  <li>consectetur adipiscing elit</li>
              </ul>
            </h6>
          </div>
          <div class="w-full hidden sm:block">
            <div class="text-neutral-500 font-medium flex flex-row text-sm justify-between">
                <div>© {(new Date().getFullYear())} Policy Portal</div>
                <div><i class="far fa-envelope"></i> info@policyportal.com</div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/5 flex flex-col justify-center p-5">
          <div className="w-full bg-main-alt section-right p-5 flex-1 flex flex-col justify-center rounded-20">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div class="form-section p-6 sm:p-10">
              <div className="pt-0 md:pt-5 h-full flex flex-col justify-center mx-auto w-full max-w-[30rem] space-y-10">
                  {children}
              </div>
            </div>
          </motion.div>
          </div>
          <div class="w-full block sm:hidden">
            <div class="text-neutral-500 font-medium flex flex-row text-sm my-8 justify-between">
                <div>© {(new Date().getFullYear())} Policy Portal</div>
                <div><i class="far fa-envelope"></i> info@policyportal.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
