import React from "react";
import { Link } from "react-router-dom";
import "../styles/auth.scss";

const Privacy = () => {
  return (
    <div className="privacy min-w-screen min-h-screen py-10 md:py-16 bg-main">
      <div className="container mx-auto">
        <Link to={"/"}>
          <img width="260" alt="Logo" src="/images/logo-white.png" />
        </Link>
        <div className="bg-white rounded-3xl p-10 mt-10">
          <div className="">
            <div className="headingx text-5xl font-bold tracking-wide text-main">Privacy Policy</div>

            <hr className="my-7" />
            <div className="text-base text-neutral-900 font-medium mb-5">Last Updated: January 04, 2024</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
