import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { getdashboard, userToken, isLoading } = useContext(AppContext);
  const [stats, setStats] = useState([]);

  const fetchDashboard = async () => {
    const response = await getdashboard();
    setStats(response);
  };

  const StatBox = ({ value, label, info, link }) => (
    <Link to={link}>
      <section className=" p-6 rounded-lg bg-main-alt border border-[#222]">
        <h6 className="text-lg text-neutral-300 font-semibold" dangerouslySetInnerHTML={{ __html: label }}></h6>
        {info && <span className="px-2 py-1 bg-neutral-200 text-neutral-500 rounded-sm text-xs font-medium leading-none">&bull;&nbsp; {info}</span>}
        <h2 className="text-6xl text-center font-bold text-white my-4">{value}</h2>
      </section>
    </Link>
  );

  useEffect(() => {
    fetchDashboard();

    if(userToken.type !== "Admin"){
      navigate("/discover");
    }
  }, []);

  return (
    <AppLayout pageName={"Dashboard"}>
      {isLoading ? 
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div> : (
        <>
          {userToken.type == "Admin" && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-3 max-sm:mt-1 gap-3">
                <StatBox link="/all-clients" value={(stats.individuals > 0) ? stats.individuals.toLocaleString() : stats.individuals} label="Total Individuals" />
                <StatBox link="/all-clients" value={(stats.organizations > 0) ? stats.organizations.toLocaleString() : stats.organizations} label="Total Organizations" />
                <StatBox link="/all-listings" value={(stats.listings > 0) ? stats.listings.toLocaleString() : stats.listings} label="Total Listings" />
              </div>
            </>
          )}
        </>
      )
      }
    </AppLayout>
  );
};

export default Dashboard;
