import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import "react-phone-number-input/style.css";
import { GoOrganization } from "react-icons/go";
import { GoPerson } from "react-icons/go";

const Register = () => {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [organization, setOrganization] = useState("");
  const [invitecode, setInvitecode] = useState("");
  const [acctype, setAcctype] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [codesent, setCodesent] = useState(false);
  const [cont, setCont] = useState(false);

  const { signup, isLoading } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      if (password.length < 6) {
        setError("Password must have at least 6 characters");
        return;
      }
      try {
        if (((acctype == 'Organization' && organization !== "") || acctype == 'Individual' && invitecode !== "") && email !== "" && name !== "" && password !== "") {
          setError("");
          const response = await signup(organization, invitecode, title, name, lastname, phone, email, password, acctype);
          if (response === "success") {
            setSuccess(true);
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Signup failed: " + error);
      }
    }
  };

  return (
    <AuthLayout page="Register" heading={codesent ? 'Check your email for a code' : 'First, enter your email'} subheading={codesent ? `We've sent a 6-character code to <span>${email}</span>. The code expires shortly, so please enter it soon.` : 'We suggest using the <span>email address you use at work.</span>'}>
        {/* <h3 className="heading">Request an Account</h3> */}
        {codesent === true ? (
          <div>
            <div className="mt-14 flex flex-row justify-center space-x-16">
              <button className="flex space-x-3 text-black font-medium">
                <img src="/images/icons/gmail.png"/> <span>Open Gmail</span>
              </button>
              <button className="flex space-x-3 text-black font-medium">
                <img src="/images/icons/outlook.png"/> <span>Open Outlook</span>
              </button>
            </div>
            <div className="text-center mt-7 font-medium">
              <div>Can't find your code? Check your spam folder!</div>
            </div>
          </div>
        ) : (
          <>
          <h3>Sign Up</h3>
          {acctype == '' && <h6 className="!text-lg !mb-0">Choose an account type</h6>}
          <div class="flex space-x-5">
            <button type="button" onClick={() => setAcctype('Organization')} className={`btn btn-main-auth-outline ${acctype == 'Organization' ? 'active' : (acctype != '' && 'nonactive')}`}>
              <GoOrganization size={20}/>
              <span>Organization</span>
            </button>
            <button type="button" onClick={() => setAcctype('Individual')} className={`btn btn-main-auth-outline ${acctype == 'Individual' ? 'active' : (acctype != '' && 'nonactive')}`}>
              <GoPerson size={20}/>
              <span>Individual</span>
            </button>
          </div>
          {acctype !== '' && 
          <form className="register-form">
            <div>
              <div className="space-y-5">
                <div className="flex space-x-5">
                  <div className="form-group w-1/5">
                    <label>Title</label>
                    <select name="title" required className="form-control" value={title} onChange={(e) => setTitle(e.target.value)}>
                      <option value={''}></option>
                      <option>Mr</option>
                      <option>Ms</option>
                      <option>Mrs</option>
                    </select>
                  </div>
                  <div className="form-group w-2/5">
                    <div>
                      <label>First Name</label>
                      <input id="name" name="name" maxLength={30} required type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                  </div>
                  <div className="form-group w-2/5">
                    <div>
                      <label htmlFor="lastname">Last Name</label>
                      <input id="lastname" name="lastname" maxLength={30} type="text" className="form-control" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                  </div>
                </div>
                { acctype == 'Organization' ?
                <>
                <div className="form-group organization">
                  <label>Organization Name</label>
                  <input id="organization" name="organization" maxLength={30} required type="text" className="form-control" value={organization} onChange={(e) => setOrganization(e.target.value)} />
                </div>
                <div className="form-group organization">
                  <label htmlFor="phone">Contact Phone Number</label>
                  <input id="phone" name="phone" required type="tel" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={20} />
                </div>
                </>
                :
                <div className="form-group invitecode">
                  <label>Organization Invitation Code</label>
                  <input id="invitecode" name="invitecode" maxLength={10} required type="text" className="form-control" value={invitecode} onChange={(e) => setInvitecode(e.target.value)} />
                </div>
                }
                <div className="form-group email">
                  <label>{acctype == 'Organization' && 'Company'} Email Address</label>
                  <input id="email" name="email" required type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
                </div>
                <div className="form-group password">
                  <label>Password</label>
                  <input id="password" name="password" required type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={255} />
                </div>
              </div> 
              {error !== "" ? <div className="text-center text-red-500 font-semibold text-md my-3">{error}</div> : <div className="py-4"></div>}
              <button type="submit" className="w-full btn btn-main-auth disabled:opacity-40 disabled:!text-[#6EBBFA] disabled:!bg-[#111] disabled:cursor-not-allowed" onClick={handleSubmit} disabled={((title == '' || name == '' || ((acctype == 'Organization' && organization == '') || (acctype == 'Individual' && invitecode == '')) || email == '' || password == '') ? true : false)}>
                {isLoading === false ? "Continue" : <Bounce animating={isLoading} />}
              </button>
              <div className="mt-5 text-neutral-500 text-sm font-medium">
                Already have an account? <Link className="text-main font-medium" to="/">Sign in</Link>
              </div>
            </div>
          </form>
          }
          </>
        )}
    </AuthLayout>
  );
};

export default Register;
