import React, { useContext, useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import AppLayout from "../layouts/AppLayout";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { PiSlidersHorizontal } from "react-icons/pi";
import MultiSelect from "../components/MultiSelect"; // Ensure to create this file for custom styles
import "../styles/discover.scss";
import "../styles/lists.scss";
import { PiUsers } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { LuArrowUpRight } from "react-icons/lu";
import { HiMiniChevronDown } from "react-icons/hi2";
import { TbChecks } from "react-icons/tb";
import Modal from "../components/Modal"; // Import the existing modal component
import { LuPlus } from "react-icons/lu";
import { TbX } from "react-icons/tb";
import { FiSearch } from "react-icons/fi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { RxPlusCircled } from "react-icons/rx";
import { LuPlusCircle } from "react-icons/lu";
import { PiPlusCircle, PiPlus } from "react-icons/pi";
import { RiEditCircleLine } from "react-icons/ri";
import { GoCheckCircle } from "react-icons/go";
import { TfiTrash } from "react-icons/tfi";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import { FaPlus } from "react-icons/fa";

const Lists = () => {
  const { addnewlist, getlists, getlistinfluencers, getinfluencer, sharewithteam, saveit, unsaveit, savetolist, removefromlist, bulkremovefromlist, userToken, isLoading } = useContext(AppContext);

  const navigate = useNavigate();

  const [influencers, setInfluencers] = useState([]);
  const [lists, setLists] = useState([]);
  const [dropdownlists, setdropdownLists] = useState([]);
  const [influencer, setInfluencer] = useState("");
  const [listId, setListId] = useState("");
  const [selectedListName, setSelectedListName] = useState("");
  const [infId, setInfId] = useState("");
  const [infIds, setInfIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [infLoading, setinfLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sharedAlready, setsharedAlready] = useState(0);
  const [savedAlready, setsavedAlready] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showNewModal, setshowNewModal] = useState(false);
  const [listName, setListName] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [listsLoading, setListsLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showListsDropdown, setShowListsDropdown] = useState(false);

  const limit = 10;

  const genericSearch = (items, searchTerm) => {
    if (!searchTerm) {
      return items;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return items.filter((item) =>
      Object.values(item).some((value) =>
        value && value.toString().toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  };

  const fetchInfluencers = useCallback(async (listId) => {
    if (listId != '') {
      if (!hasMore) return;
      setLoadingMore(true);
      const response = await getlistinfluencers(searchTerm, listId, limit, offset);
      if (response.length > 0) {
        if(influencers.length == 0){
          setInfluencers(response);
        }
        else{
          setInfluencers((prev) => [...prev, ...response]);
        }
        setOffset((prev) => prev + limit);
      } else {
        setHasMore(false);
      }
      setLoadingMore(false);
    }
  }, [getlistinfluencers, searchTerm, offset, hasMore]);

  const debouncedFetchInfluencers = useCallback(debounce(fetchInfluencers, 300), [searchTerm]);

  useEffect(() => {
    fetchInfluencers(listId); // Pass the current listId here
  }, [listId, debouncedFetchInfluencers]);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
    setInfluencers([]);
    setOffset(0);
    setHasMore(true);
    fetchInfluencers(listId); // Pass the current listId here
  };

  const handleListChange = (newListId, name) => {
    setListId(newListId);
    setSelectedListName(name);
    setInfluencers([]);
    setOffset(0);
    setHasMore(true);
    fetchInfluencers(newListId);
    setInfId('');
    setShowListsDropdown(false);
  };

  useEffect(() => {
    const fetchInfluencer = async () => {
      if(infId != ''){
        setinfLoading(true);
        setsharedAlready(0);
        const response = await getinfluencer(infId);
        setInfluencer(response[0]);
        setsharedAlready(response[0].ifshared);
        setinfLoading(false);
      }
    };

    fetchInfluencer();
  }, [infId]);

  const handleRemove = (value) => {
    setSelectedOptions(selectedOptions.filter(option => option !== value));
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loadingMore) {
      fetchInfluencers(listId);
    }
  };

  const shareWithTeam = async () => {
    if(infId != ''){
      const response = await sharewithteam(infId);
      if(response == 'success'){
        setsharedAlready(1);
      }
    }
  }

  const saveIt = async () => {
    if(infId != ''){
      const response = await saveit(infId);
      if(response == 'success'){
        setsavedAlready(1);
      }
    }
  }

  const unsaveIt = async () => {
    if(infId != ''){
      const response = await unsaveit(infId);
      if(response == 'success'){
        setsavedAlready(0);
        if(listId == userToken.userid){
          setInfluencers(influencers.filter(influencer => influencer.influencerid !== infId));
          setInfId('');
        }
      }
    }
  }

  const saveToList = async (listId) => {
    if(infId != ''){
      const response = await savetolist(infId, listId);
      setdropdownLists(dropdownlists.filter(list => list.listid !== listId));
      if(response == 'success'){
        setShowListsDropdown(false);
      }
    }
  }

  const removeFromList = async () => {
    if(infId != ''){
      const response = await removefromlist(infId, listId);
      if(response == 'success'){
        setInfluencers(influencers.filter(influencer => influencer.influencerid !== infId));
        setInfId('');
      }
    }
  }
  
  const handleCheckboxChange = (influencerId) => {
    setInfIds((prev) =>
      prev.includes(influencerId)
        ? prev.filter((id) => id !== influencerId)
        : [...prev, influencerId]
    );
  };
  
  const handleSelectAll = () => {
    if (infIds.length === influencers.length) {
      setInfIds([]); // Deselect all
    } else {
      setInfIds(influencers.map((inf) => inf.influencerid)); // Select all
    }
  };

  useEffect(() => {
    if (listName !== "") {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [listName]);

  const fetchLists = async () => {
    const response = await getlists();
    setLists(response);
    setdropdownLists(response);
  };

  useEffect(() => {
    fetchLists();
  }, []);

  const handleAddNewList = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    await addnewlist(listName);
    setshowNewModal(false);
    setListName('');
    setFormLoading(false);
    fetchLists();
  };

  const handleBulkDelete = async () => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await bulkremovefromlist(infIds, listId);
          if (response === 'success') {
            setInfluencers(influencers.filter(influencer => !infIds.includes(influencer.influencerid)));
            setInfIds([]);
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure to remove?',
          text: "You won't be able to undo this action",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Remove'
        });
  
        if (result.isConfirmed) {
          const response = await removefromlist(todelete, listId);
          if (response === 'success') {
            setInfluencers(influencers.filter(influencer => influencer.influencerid !== todelete));
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };
  

  return (
    <AppLayout pageId="lists" pageName={"Lists"}>
      <section>
        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <div className="space-y-5">
            <div className="flex space-x-8">
              <div className={`transition-all ${infId !== '' ? 'w-4/6' : 'w-full'}`}>
                <div className="section-left flex flex-col">
                  <div className="flex justify-between">
                    <div>
                      { showNewModal && 
                      <Modal heading="New List" isOpen={showNewModal} onClose={() => setshowNewModal(false)}>
                        <form onSubmit={handleAddNewList}>
                          <div className="modal-body">
                            <div className="mb-8">
                              <label>List Name</label>
                              <input className="form-control" type="text" maxLength={30} value={listName} onChange={(e) => setListName(e.target.value)} placeholder="" required/>
                            </div>
                          </div>
                          <div className="modal-footer">
                            {/* <button type="submit" className="btn-submit">Submit</button> */}
                            <button type="submit" className="btn-submit" disabled={formLoading || !isFormChanged}>
                              {formLoading === false ? "Submit" : <Bounce className="my-2" animating={formLoading} />}
                            </button>
                          </div>
                        </form>
                      </Modal>
                      }
                      <button className={`btn-new flex items-center`} onClick={() => setshowNewModal(true)}><FaPlus size={16} className="mr-1.5"/> New list</button>
                    </div>
                    <div className="page-head">
                      {/* <h4 className="text-lg text-main font-semibold">{lists.length + 2} lists</h4> */}
                    </div>
                  </div>
                  <div className="flex flex-col space-y-3 mt-5 lists-wrapper">
                    {/* <div className={`btn-list-item-wrapper ${listId === userToken.userid && 'active'}`} onClick={() => { if(listId !== userToken.userid){ handleListChange(userToken.userid, 'Saved') } }}><button className={`btn-list-item ${listId === userToken.userid && 'active'}`}>Saved</button></div>
                    <div className={`btn-list-item-wrapper ${listId === userToken.teamid && 'active'}`} onClick={() => { if(listId !== userToken.teamid){ handleListChange(userToken.teamid, 'Shared with Team') } }}><button className={`btn-list-item ${listId === userToken.teamid && 'active'}`}>Shared with Team</button></div> */}
                    { lists.map((list, index) => {
                      return(
                        <div className="list-card" onClick={() => navigate(`/lists/${list.listid}`)} key={index}>
                          <h5 className="text-2xl text-main font-medium">{list.name}</h5>
                          <div className="grid grid-cols-2 sm:grid-cols-3 mt-3 text-sm">
                            <div>
                              <div><span className="text-neutral-600">Members:</span> <span className="font-semibold">{list.totalmembers}</span></div>
                            </div>
                            <div>
                              <div><span className="text-neutral-600">Created:</span> <span className="font-medium">{list.addedatdate}</span></div>
                            </div>
                          </div>
                        </div>
                        // <div className={`btn-list-item-wrapper ${listId === list.listid && 'active'}`} onClick={() => { if(listId !== list.listid){ handleListChange(list.listid, list.name) } }}><button className={`btn-list-item ${listId === list.listid && 'active'}`}>{list.name}</button></div>
                      )
                    }) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </AppLayout>
  );
};

export default Lists;
