import React from 'react';
import { FaTimes } from "react-icons/fa";
import '../styles/modal.scss'
import { motion } from "framer-motion";

function Modal({ heading, isOpen, onClose, children }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
      <div className="modal fixed inset-0 flex items-center justify-center z-40" onClick={handleOverlayClick}>
        {heading != "Member Info" && <div className="modal-overlay absolute inset-0 bg-neutral-900 opacity-50"></div> }
        {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}> */}
        <div className={`modal-content bg-main-alt border border-[#222] shadow-2xl shadow-neutral-800 ${heading == 'Member Info' ? 'w-[90%] h-[80%]' : 'w-[30rem]'} max-w-full rounded-lg z-50`} onClick={handleContentClick}>
          <div className="modal-header p-6 sm:px-10 rounded-t-lg text-black flex justify-between items-center border-b border-b-neutral-800">
            <h2 className='text-2xl font-semibold text-white'>{heading}</h2>
            <button className="modal-close text-highlight text-neutral-600 hover:text-white" onClick={onClose}><FaTimes size={20}/></button>
          </div>
          <div className="modal-body p-6 sm:p-10">
            {children}
          </div>
        </div>
        {/* </motion.div> */}
      </div>
  );
}

export default Modal;
