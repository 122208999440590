import AppLayout from "../layouts/AppLayout";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { GoPerson } from "react-icons/go";

const AddClient = () => {
  const { userId } = useParams();

  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [organization, setOrganization] = useState("");
  const [acctype, setAcctype] = useState("");
  const [invitecode, setInvitecode] = useState("");
  const [organizationid, setOrganizationid] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [modalopen, setModalopen] = useState(false);
  const [reviewaction, setReviewaction] = useState("");

  const { getorganizations, getclient, reviewclient, addclient, updateclient, isLoading } = useContext(AppContext);

  useEffect(() => {
    const fetchClient = async () => {
      const response = await getclient(userId);
      // Update the state with the received products
      if (response) {
        setClient(response[0]);
        setAcctype(response[0].type);
        setTitle(response[0].title);
        setName(response[0].name);
        setLastname(response[0].lastname);
        setOrganization(response[0].organization);
        setOrganizationid((response[0].organizationid) != "" ? (response[0].invitecode) : (response[0].invitecode));
        setEmail(response[0].email);
        setPhone(response[0].phone);
        setStatus(response[0].status);
      }
    };

    if (userId) {
      fetchClient();
    }
  }, [userId]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (((acctype == 'Organization' && organization !== "") || acctype == 'Individual' && organizationid !== "") && email !== "" && name !== "") {
          setError("");
          let response = "";
          if (userId) {
            response = await updateclient(organization, organizationid, title, name, lastname, phone, email, password, acctype, userId);
          } else {
            response = await addclient(organization, organizationid, title, name, lastname, phone, email, password, acctype);
          }
          if (response === "success") {
            navigate("/all-clients");
          }
          else{
            setError(response);
            return;
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  const reviewClient = async () => {
    const response = await reviewclient({ userid: userId, reviewaction });
    if (response === "success") {
      setModalopen(false);
      setReviewaction("");
      navigate("/all-clients");

      //   const response = await getmylistings();
      //   setProducts(response);
    }
  };

  const prepareaction = (action) => {
    setModalopen(true);
    setReviewaction(action);
  };

  const unprepareaction = () => {
    setModalopen(false);
    setReviewaction("");
  };

  const fetchOrganizations = async () => {
    const response = await getorganizations();
    setOrgs(response);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <AppLayout pageName={`${userId ? 'Update' : 'Add'} Client`}>
      <section>
        {status === "Under Review" ? (
          <>
            <div className="flex items-center p-4 mb-5 text-sm text-red-500 rounded-lg bg-red-100" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">Under Review!</span> This member needs to be reviewed by the admin.
              </div>
            </div>

            <div className="flex space-x-4 mb-5">
              <button onClick={() => prepareaction("Approved")} type="button" className="btn-submit !bg-green-600  !border-green-600" disabled={isLoading}>
                <span>Approve</span>
                <span>{isLoading === false ? <FaCheck /> : <Bounce animating={isLoading} />}</span>
              </button>
              <button onClick={() => prepareaction("Rejected")} type="button" className="btn-submit !bg-red-500  !border-red-500" disabled={isLoading}>
                <span>Reject</span>
                <span>{isLoading === false ? <FaTimes /> : <Bounce animating={isLoading} />}</span>
              </button>
            </div>

            <Transition.Root show={modalopen} as={Fragment}>
              <Dialog as="div" className="relative z-50" onClose={unprepareaction}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="fixed inset-0 hidden bg-gray-50 bg-opacity-75 blur-xl transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                  <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                      <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                        <div className="relative w-full items-center overflow-hidden bg-neutral-800 rounded-lg text-white shadow-2xl p-8">
                          <div className="w-full">
                            <h5 className="text-xl font-medium mb-2">Are you sure you want to {reviewaction} this member?</h5>
                            <h5 className="text-md text-neutral-400 font-light">This action cannot be undone</h5>
                            <button type="button" className="absolute top-3 right-3 text-gray-400 hover:text-gray-500" onClick={() => unprepareaction()}>
                              <span className="sr-only">Close</span>
                              <RxCross2 className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                          {reviewaction !== "" ? (
                            <div className="px-3 pt-7 mb-3 flex justify-end space-x-2">
                              <button disabled={isLoading} className="btn-main-inverse" onClick={() => unprepareaction()}>
                                No, Cancel
                              </button>
                              <button disabled={isLoading} className="btn-main !px-6" onClick={() => reviewClient()}>
                                {isLoading === false ? "Yes, Proceed" : <Spinner animating={isLoading} />}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`lg:w-2/4 w-full bg-main-alt p-8 pb-10 rounded-xl ${status === "Under Review" ? "pointer-events-none" : ""}`}>
          {acctype == '' && <h6 className="text-xl mb-4 font-semibold text-main">Choose an account type</h6>}
          <div class="flex space-x-5">
            { userId ? 
            <button type="button" className={`btn btn-main-auth-outline active`}>
              { client.type == 'Individual' ? <GoPerson size={20}/> : <GoOrganization size={20}/> }
              <span>{client.type}</span>
            </button>
            : 
            <>
            <button type="button" onClick={() => setAcctype('Organization')} className={`btn btn-main-auth-outline ${acctype == 'Organization' ? 'active' : (acctype != '' && 'nonactive')}`}>
              <GoOrganization size={20}/>
              <span>Organization</span>
            </button>
            <button type="button" onClick={() => setAcctype('Individual')} className={`btn btn-main-auth-outline ${acctype == 'Individual' ? 'active' : (acctype != '' && 'nonactive')}`}>
              <GoPerson size={20}/>
              <span>Individual</span>
            </button>
            </>
            }
          </div>
          {acctype !== '' && 
          <>
          <div className="space-y-5 mt-8">
            <div className="flex space-x-5">
              <div className="form-group w-1/5">
                <label>Title</label>
                <select name="title" required className="form-control" value={title} onChange={(e) => setTitle(e.target.value)}>
                  { !userId && <option value={''}></option> }
                  <option>Mr</option>
                  <option>Ms</option>
                  <option>Mrs</option>
                </select>
              </div>
              <div className="form-group w-2/5">
                <div>
                  <label>First Name</label>
                  <input id="name" name="name" maxLength={30} required type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
              </div>
              <div className="form-group w-2/5">
                <div>
                  <label htmlFor="lastname">Last Name</label>
                  <input id="lastname" name="lastname" maxLength={30} type="text" className="form-control" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                </div>
              </div>
            </div>
            { acctype == 'Organization' ?
            <>
            <div className="form-group organization">
              <label>Organization Name</label>
              <input id="organization" name="organization" maxLength={30} required type="text" className="form-control" value={organization} onChange={(e) => setOrganization(e.target.value)} />
            </div>
            <div className="form-group organization">
              <label htmlFor="phone">Contact Phone Number</label>
              <input id="phone" name="phone" required type="tel" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} maxLength={20} />
            </div>
            </>
            :
            <div className="form-group invitecode">
              <label>Organization</label>
              {/* <input id="invitecode" name="invitecode" maxLength={10} required type="text" className="form-control" value={invitecode} onChange={(e) => setInvitecode(e.target.value)} /> */}
              <select id="organizationid" name="organizationid" className="form-control" value={organizationid} onChange={(e) => setOrganizationid(e.target.value)} required>
                <option disabled selected value="">
                  Select
                </option>
                {orgs.map((org) => (
                  <option key={org.organizationid} value={org.organizationid}>
                    {org.organization}
                  </option>
                ))}
              </select>
            </div>
            }
            <div className="form-group email">
              <label>{acctype == 'Organization' && 'Company'} Email Address</label>
              <input id="email" name="email" required type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} />
            </div>
            {!userId &&
            <div className="form-group password">
              <label>Password</label>
              <input id="password" name="password" required type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} maxLength={255} />
              <div className="text-xs text-neutral-600 font-medium mt-2">Password must have at least 6 characters</div>
            </div>
            }
          </div> 
          <div>
            {error !== "" ? <div className="text-left text-red-500 font-semibold text-md my-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
            {status === "Under Review" ? (
              ""
            ) : (
              <button type="submit" className="btn-submit" disabled={((title == '' || name == '' || ((acctype == 'Organization' && organization == '')) || email == '' || (!userId && password == '') || isLoading) ? true : false)}>
                <span>Save Changes</span>
                <span>{isLoading === false ? <HiArrowNarrowRight className="ml-2"/> : <Bounce className="ml-2" animating={isLoading} />}</span>
              </button>
            )}
          </div>
          </>
          }
        </form>
      </section>
    </AppLayout>
  );
};

export default AddClient;
